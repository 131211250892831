@import '../../styles/variables';
@import '../../styles/mixins/paragraph';

.mainContainer {
    overflow: hidden;
    background: rgba($color: $gray-6, $alpha: 1.0);

    .logoContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        flex-direction: column;
        top: 5%;

        p {
            @include paragraph(16px, $gray-4, 0);
            text-align: center;
            position: relative;
            margin: 20px;
        }

        div {
            width: inherit;
            margin-left: 0;
            display: flex;
            justify-content: center;
        }

        .infoIcon {
            margin-left: 10px;
            position: absolute;
            right: 0;
        }
    }

    div {
        &:focus {
            outline: none;
        }
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    .top {

        svg {
            position: absolute;
            bottom: 15px;
        }

        &:active {
            opacity: .4;
        }

        &:before {
            width: 135px;
            height: 135px;
            background-repeat: no-repeat;
            position: absolute;
            bottom: 0px;
            transform: rotate(180deg);
            display: flex;
            justify-content: flex-end;
        }
        position: relative;
        display: flex;
        flex: 1;
        justify-content: center;
        width: 150px;
        height: 200px;
        top: 20px;
        z-index: 9;
        img {
            transform: scale(1);
            display: flex;
            align-self: flex-end;
            position: relative;
            top: -20px;
        }
    }
    .middle {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex: 1;
        width: 100%;
        .oneButton {
            svg {
                z-index: 9;
            }

            &:active {
                opacity: .4;
            }

            position: relative;
            height: 200px;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:first-of-type {
                position: absolute;
                left: 0;
                &:before {
                    width: 135px;
                    height: 135px;
                    position: absolute;
                    background-repeat: no-repeat;
                    transform: scale(1);
                    transform: rotate(90deg);
                    top: unset;
                    left: -30px;
                    z-index: 1;
                }
            }
            &:last-of-type {
                position: absolute;
                right: 0;
                &:before {
                    width: 135px;
                    height: 135px;
                    position: absolute;
                    background-repeat: no-repeat;
                    transform: scale(1);
                    transform: rotate(-90deg);
                    top: unset;
                    left: 45px;
                    z-index: 1;
                }
            }
            &.playPause {
                background: rgba($color: $purple, $alpha: 1.0);
                width: 140px;
                height: 140px;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &.dirButton {

            }
        }
    }
    .bottom {
        svg {
            position: absolute;
            top: -10px;
        }
        &:active {
            opacity: .4;
        }
        &:before {
            width: 135px;
            height: 135px;
            background-repeat: no-repeat;
            position: absolute;
            top: -30px;
            display: flex;
            justify-content: flex-end;
        }
        top: 15px;
        position: relative;
        display: flex;
        flex: 1;
        width: 100%;
        justify-content: center;
        img {
            transform: scale(1);
            display: flex;
            align-self: flex-start;
        }
    }
}