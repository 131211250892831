/* FONTS */

@import './fonts';

/* COLORS */

$purple: #8380FF;
$orange: #F4A836;
$green: #09b813;
$red: #f26457;
$gray-1: #1E1E1E;
$gray-2: #2D2D2D;
$gray-3: #3A3A3A;
$gray-4: #C1C1C1;
$gray-5: #2E2E2E;
$gray-6: #444444;
$gray-7: #4d4d4d;

$warning: $orange;
$success: $green;
$error: $red;

/* BREAKPOINTS */

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

/* VARIABLES */

$border-radius: 5px;
$login-box-border-radius: $border-radius;
$login-box-bg-color: $gray-2;
$modal-drop-shadow-color: #000000;
$modal-border-radius: $border-radius;
$modal-backdrop-blur-value: 5px;
$modal-background-color: $gray-2;
$overlay-bg-color: $gray-1;
