@import '../../styles/variables';
@import '../../styles/mixins/paragraph';

.loginBoxContainer {
    position: absolute;
    border-radius: $login-box-border-radius;
    box-shadow: 3px 3px 20px rgba($color: #000000, $alpha: 0.8);
    background-color: $login-box-bg-color;
    top: 50px;
    right: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    z-index: 2;

    .additionalInfo {
        width: 275px;

        p {
            @include paragraph(14px, #ffffff);
            letter-spacing: 0;
            line-height: 1.7;
            padding: 10px 20px;

            a {
                color: $purple;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &.registering {
        transition: all .3s ease;
        width: 280px;
        height: 100px;
        padding-top: 0;
        padding-bottom: 0;
    }

    &.saveContainer {
        width: 280px;
        height: 150px;
        .success {
            &.hidden {
                display: none;
            }
            &.visible {
                display: block;
            }
            width: 100%;
            position: absolute;
            bottom: 0;
            overflow: hidden;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba($color: $success, $alpha: 0.8);
            p {
                @include paragraph(12px, #ffffff, 0);
                text-transform: uppercase;
                padding: 20px 0;
            }
        }
    }
    &.hidden {
        display: none;
    }
    &.visible {
        display: block;
    }
    .loginInput {
        margin: 10px;
        input {
            font-size: 14px !important;
        }
    }
    .loginInputWError {
        margin: 0 10px;
        input {
            font-size: 14px !important;
            border: 2px solid $orange !important;
        }
    }
    .loginBtn {
        margin: 0;
        margin-top: 20px;
        button {
            height: 45px;
            font-weight: 300;
        }
    }
    &.itemBoxContainer {
        right: unset;
        padding: 0;
        .savedItems {
            list-style-type: none;
            padding: 0;
            width: 260px;
            li {
                @include paragraph(15px, #ffffff);
                padding: 10px 0;
                transition: all .2s ease;
                display: flex;
                justify-content: space-between;
                padding: 10px 10px;
                padding-left: 20px;
                &:hover {
                    cursor: pointer;
                    background-color: rgba($color: $gray-3, $alpha: 1.0);
                    transition: all .2s ease;
                }
                .rootIcon {
                    display: flex;
                    align-items: center;
                    .icon {
                        opacity: 0.6;
                        margin-left: 15px;
                        &:focus {
                            outline: none;
                        }
                        &:hover {
                            transition: all .2s ease;
                            opacity: 1;
                            cursor: pointer;
                        }
                        margin-left: 15px;
                        &.rotate {
                            transform: rotate(90deg);
                            position: relative;
                            top: 2px;
                        }
                        color: $purple;
                        svg {
                            fill: $purple;
                        }
                    }
                }
                &:last-of-type {
                    border: none;
                }
            }
        }
    }

    .errorContainer {
        &.hidden {
            display: none;
        }
        padding-top: 10px;
        p {
            @include paragraph(14px, $orange, 0);
        }
    }

    .forgottenContainer {
        &:focus {
            outline: none
        }

        p {
            @include paragraph(14px, $purple, 0);
            margin-top: 20px;
            opacity: .8;
            &:hover {
                cursor: pointer;
                opacity: 1;
                transition: all 0.2s ease;
                text-decoration: underline;
            }
        }
    }
}

.modal {
    padding: 30px;

    h3 {
        @include paragraph(14px, #ffffff);
        font-weight: 300;
        margin: 0;
        margin-bottom: 20px;
    }

    p {
        @include paragraph(14px, #ffffff);
    }

    .buttonContainer {
        display: flex;
        padding-top: 30px;
        button {
            margin: 0 10px;
            font-weight: 300;
        }
    }
}

.overLay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}