@import '../../styles/variables';
@import '../../styles/mixins/hideScrollbar';

.rootContainer  {
    background-color: rgba($color: #000000, $alpha: 1.0);

    .scrollerContainer {
        overflow-wrap: break-word;
        margin-left: auto;
        margin-right: auto;
        height: calc(100vh - 90px);
        overflow: scroll;
        .scroller {
            padding: 50px;
            p {
                margin: 0;
                padding: 0;
                white-space: pre-wrap;
						}
						
						.breakContainer {
							margin-top: 100px;
							margin-bottom: 80px;
						}
				}
				
				.segment {
					border-width: 2px;
					border-style: solid;
					border-radius: 20px;
					margin: 50px 0;

					&:first-of-type {
						margin: 0;
					}

					.segmentTextContainer {
						p {
							padding: 10px 60px;
						}
					}

					.segmentTitleContainer {
						padding: 10px 60px;

						p {
							font-family: 'Barlow';
							font-size: 40px;
						}
					}
				}
		}
		
		&.light {
			background: rgba($color: #ffffff, $alpha: 1);
			color: #000000;
		}

		&.dark {
			background: rgba($color: #000000, $alpha: 1);
			color: #ffffff;
		}
}

@include hideScrollBar('scrollerContainer')