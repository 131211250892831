@import '../../styles/variables';

.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;	
}

.colorPickerContainer {
		display: block;
		position: absolute;
		top: -50px;
		right: -50px;
		padding: 20px;
		background: rgba($color: $gray-2, $alpha: 1);
		border-radius: $border-radius;
		box-shadow: 3px 3px 20px rgba($color: #000000, $alpha: 0.5);
		z-index: 99;

		&.hidden {
			visibility: hidden;
			display: none;
		}

		&.visible {
			display: block;
		}
}