@import "../../../styles/variables";
@import "../../../styles/mixins/keyframes";

@include keyframes(opacity) {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.modalOverlay {
	animation: opacity 0.3s;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background: $overlay-bg-color;
	opacity: 0.9;
	z-index: 9;
	backdrop-filter: blur($modal-backdrop-blur-value / 2);
	left: 0;
}

.modalWrapper {
	width: fit-content;
	height: fit-content;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba($color: $modal-background-color, $alpha: 0.9);
	margin-left: auto;
	margin-right: auto;
	margin-top: auto;
	margin-bottom: auto;
	bottom: 0;
	outline: none;
	border-radius: $login-box-border-radius;
	z-index: 9;
	animation: opacity 0.3s;

	.modal {
		display: flex;
		flex-direction: column;
		padding: 30px;

		div {
			.header {
				padding: 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				button {
					border: 0;
					display: flex;
					justify-content: flex-end;
					flex: 1;
					outline: none;

					&:hover {
						cursor: pointer;
						outline: none;
					}
				}
			}

			.closeBtn {
				background: none;
				border: none;
				box-shadow: none;
				align-self: flex-end;
				display: flex;
				justify-content: flex-end;
				width: 100%;

				&:focus {
					outline: none;
				}

				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}
