@import '../../styles/variables';
@import '../../styles/mixins/paragraph.scss';

.actionSidebarContainer {
    background-color: $gray-2;
    .innerContainer {
        margin: 30px 40px;
        max-width: 230px;

        .testAnimation {
            padding: 10px 0;
            display: flex;
            justify-content: flex-start;
        }

        .playButtonContainer {

            button {
                font-weight: 500;
                text-transform: none;
                font-size: 16px;
                letter-spacing: 0.45px;
                justify-content: center;
                align-items: center;
                display: flex;
            }

            .updateBtn {
                margin-bottom: 20px;
                display: flex;

                button {
                    box-shadow: 3px 3px 20px rgba($color: #000000, $alpha: 0.5);
                }
            }

            .playBtn {
                transition: all 0.2 ease-in-out;

                &.hidden {
                    opacity: 0;
                }

                &.visible {
                    opacity: 1;
                }

                button {
                    box-shadow: 3px 3px 20px rgba($color: #000000, $alpha: 0.5);
                    text-transform: capitalize;

                    div {
                        position: relative;
                        top: 2px;
                    }
                }
            }
        }

        .about {
            a {
                @include paragraph(14px, #ffffff, 0.42px);
                text-decoration: none;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    color: $purple;
                }
            }
        }

        .addressInput {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}