@import "../../styles/variables";
@import "../../styles/mixins/paragraph";

.howtoUseModalWrapper {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}

.aboutModalOverlay {
	animation: opacity 0.3s;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background: $overlay-bg-color;
	opacity: 1;
	z-index: 9;
	backdrop-filter: blur($modal-backdrop-blur-value / 2);
	left: 0;
}

.aboutWrapper {
  max-height: 100vh;

  h2 {
    @include paragraph(30px, #ffffff, 0);
    margin-top: 40px;
    margin-bottom: 10px;
  }

  h3 {
    @include paragraph(20px, $purple, 0);
    margin-top: 40px;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      &:before {
        content: '–';
        display: inline-block;
        text-indent: -2em;
        color: $purple;
      }
    }
  }
}