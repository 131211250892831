@import "../../styles/variables";
@import "../../styles/mixins/paragraph";

.forgottenPasswordModal {
  width: 500px;
  padding: 20px;
  p {
    @include paragraph(14px, #ffffff, 0.42);
  }

  .inputContainer {
    display: flex;
    justify-content: center;

    .input {
      height: 45px;
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }

  .btnContainer {
    display: flex;
    justify-content: space-between;

    button {
      height: 45px;
    }
  }
}

.emailSentContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  p {
    margin: 20px 0;
  }
}