@import '../../../styles/variables';
@import '../../../styles/mixins/paragraph';

.buttonContainer {
    .button {
        min-width: 242px;
        height: 57px;
        border-radius: 32px;
        background-color: rgba($color: $purple, $alpha: 0.9);
        text-transform: uppercase;
        @include paragraph(14px, #fff, 0.85px);
        border: none;
        font-weight: 800;

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
            background-color: rgba($color: $purple, $alpha: 1);
            transition: all .2s ease;
        }

        &:focus {
            outline: none;
        }

        &.negative {
            background: rgba($color: $gray-6, $alpha: 1.0);
        }
        &:disabled {
            background: rgba($color: $gray-6, $alpha: 1.0);
        }

        &.hasIcon {
            display: flex;
            justify-content: center;
        }

        .icon {
            margin-right: 10px;
            width: 24px;
            height: 24px;
        }
    }
    .linkButton {
        border: none;
        background-color: transparent;
        @include paragraph(14px, #fff, 0.85px);
        transition: all .2s ease;

        &:hover {
            cursor: pointer;
            color: $purple;
            transition: all .2s ease;
        }

        &:focus {
            outline: none;
        }

        &:disabled {
            opacity: 0.5;

            &:hover {
                pointer-events: none;
            }
        }
    }
}