.loadingOverlay  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.inlineLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}