@import '../../styles/variables';
@import '../../styles/mixins/label';

.toggleWrapper {
    display: flex;
    flex-direction: column;
    width: 185px;
    margin: 10px -10px;
    p {
        margin: 0;
        padding: 0;
        @include labelText;
        margin-bottom: 20px;
        margin-left: -10px;
        font-weight: 300;
    }
    .react-toggle {
        position: relative;
        margin-left: -10px;
        &.react-toggle--checked {
            .react-toggle-thumb {
                border: none;
                background-color: $purple;
                box-shadow: none;
            }
        }
        .react-toggle-track {
            background-color: $gray-1;
            width: 65px;
            height: 25px;
        }
        .react-toggle-thumb {
            width: 36px;
            height: 25px;
            border-radius: 37px;
            border: none;
            &:focus {
                outline: none;
            }
        }
    }
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background: rgba($color: $gray-1, $alpha: 0.5);
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background: rgba($color: $gray-1, $alpha: 0.5);
}

.react-toggle--focus {
    .react-toggle-thumb {
        &:focus {
            outline: none;
        }
        box-shadow: none;
    }
}