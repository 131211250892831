@import '../../styles/variables';
@import '../../styles/mixins/paragraph';

.passwordRecoveryWrapper {
	overflow: hidden;

	.dark {
		background: rgba($color: $gray-2, $alpha: 1.0);
		height: 100vh;
	}

	.middle {
		background: rgba($color: $gray-3, $alpha: 1);
		padding: 50px 20px;
		height: 100vh;

		.titleContainer {
			.logo {
				padding-bottom: 100px;
			}

			max-width: 620px;
			margin-left: auto;
    	margin-right: auto;
		}

		.inputContainer {
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			max-width: 620px;
			margin-left: auto;
			margin-right: auto;
			}
		.buttonContainer {
			padding-top: 0;
			max-width: 620px;
			margin-left: auto;
			margin-right: auto;

			button {
				height: 45px;
			}
		}

		.info {
			max-width: 620px;
			margin-left: auto;
			margin-right: auto;

			svg {
				stroke: #ffffff;
			}

      &.hidden {
        display: none;
      }

      &.success {
        background: rgba($color: $success, $alpha: 0.9);
      }

      &.warning {
        background: rgba($color: $warning, $alpha: 0.9);
      }

      &.error {
        background: rgba($color: $error, $alpha: 0.9);
      }

      display: flex;
      align-items: center;
      padding: 20px;
      border-radius: $border-radius;
      p {
        @include paragraph(14px, #ffffff, 0.42px);
        margin-left: 10px;
      }
    }
	}

	p {
		@include paragraph(15px, #ffffff, 0.39px);
		line-height: 1.7;
		margin: 10px 0;
		ul {
			list-style-type: none;
		}

		a {
			color: $purple;
			text-decoration: none;

			&:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}

	h1 {
		@include paragraph(25px, #ffffff, 0.39px);
		line-height: 1.7;
		margin: 10px 0;
		font-weight: 700;
	}
}