@import '../../../styles/variables';
@import '../../../styles/mixins/heading';

.textpreviewContainer {
    color: #ffffff;

    .innerContainer {
        display: flex;
        justify-content: center;
    }
    p {
        margin: 0;
    }
    .mirroredContainer {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background: rgba($color: #000000, $alpha: 0.3);
        width: 240px;
        height: 116px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .mirrored {
            padding: 20px;
            @include heading;
            opacity: 0.9;
            word-break: break-all;
            font-weight: 600;
        }
    }
    .textContainer {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background: rgba($color: #000000, $alpha: 1.0);
        width: 240px;
        height: 116px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .text {
            padding: 20px;
            @include heading;
            word-break: break-all;
            font-weight: 600;
        }
    }
}