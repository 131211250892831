@import '../../styles/variables';
@import '../../styles/mixins/paragraph';

.segmentsHeader {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: flex-start;
    .button {
        background: transparent;
        border: none;
        box-shadow: none;
        width: fit-content;
        display: flex;
        align-items: center;
        padding-left: 0;

        &:last-of-type {
            @include paragraph(14px, #fff);
            font-weight: 400;
            margin-left: 30px;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            p, svg {
                text-decoration: none;
                cursor: pointer;
                color: $purple;
                transition: all .2s ease;
            }
        }
    }

    .addPrompterIcon {
        color: #ffffff;
        width: 24px;
        height: 24px;
    }

    p {
        @include paragraph(15px, #fff, 0);
        margin-left: 5px;
    }
}

.previewContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;

    .tabContainer {
        display: none;
        position: relative;
        margin: 30px;

        &:after {
            content: '';
            width: 100%;
            height: 2px;
            background-color: $purple;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .tabButton {
            border: none;
            background: transparent;
            @include paragraph(15px, #fff);
            height: 50px;
            padding: 8px 50px;
            margin-right: 10px;

            &:focus {
                outline: none;
            }

            &.tabButtonActive {
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                border-width: 2px;
                border: 2px solid $purple;
                border-bottom: 0;
                border-style: solid;
                background-color: $purple;
                color: #fff;
            }
        }
    }
    .innerContainer {
        margin: 0 40px;
        padding: 20px 0;
    }

    &::-webkit-scrollbar-thumb {
        background: #3C3F48;
        border-radius: 20px;
        position: relative;
    }
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        margin: 30px 0;
    }
}

.previewRoot {
    background: rgba($color: $gray-3, $alpha: 1);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.innerContainer {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 100px;
}