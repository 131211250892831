@import '../../../styles/variables';
@import '../../../styles/mixins/paragraph';

.selectorContainer {
    display: flex;
    width: 185px;
    .label {
        @include paragraph(13px, #ffffff, 0px);
        text-align: center;
        text-transform: capitalize;
    }
    .selectorItem {
        padding: 3px;
        margin-right: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 36px;
        &:hover {
            cursor: pointer;
            transition: all .4s ease;
            background-color: rgba($color: $purple, $alpha: 0.2);
        }
    }
}