@mixin hideScrollBar($selector) {
    .#{$selector} {
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
      
        &::-webkit-scrollbar {
          width: 1px;
        }
      
        &::-webkit-scrollbar-track {
          background: transparent;
        }
      
        &::-webkit-scrollbar-thumb {
          background-color: transparent;
        }
      }
}