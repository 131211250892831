@import "../../styles/variables";

.aboutModalWrapper {
	width: 100%;
	overflow: hidden;
	overflow-y: auto;
	height: 100%;
}

.aboutModalOverlay {
	animation: opacity 0.3s;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background: $overlay-bg-color;
	opacity: 1;
	z-index: 9;
	backdrop-filter: blur($modal-backdrop-blur-value / 2);
	left: 0;
}